import { ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

import i18n from "i18next";

import { Box, Spinner } from "@twilio-paste/core";

import Login from "./login/login";
import AppContainer from "./AppContainer";
import { actionCreators, AppState } from "../store";
import { getAdminToken } from "../api";
import {
  getIdentity,
  setPassword,
  setIdentity,
} from "../../utils/storage.utils";
import { convertLanguageToCountry } from "../../utils/languageCountryConverter";

function App(): ReactElement {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { setUserLanguage, login } = bindActionCreators(
    actionCreators,
    dispatch
  );
  const token = useSelector((state: AppState) => state.token);

  const identity = getIdentity() ?? "";

  useEffect(() => {
    console.log("load app", identity);
    if (identity.length > 0) {
      getAdminToken()
        .then((token) => {
          login(token);
        })
        .catch(() => {
          setIdentity("");
          setPassword("");
        })
        .finally(() => {
          setLoading(false);
        });
    }
    setUserLanguage(convertLanguageToCountry(i18n.language));
  }, []);

  const setToken = (token: string) => {
    login(token);
    setLoading(false);
    setUserLanguage(convertLanguageToCountry(i18n.language));
  };

  if ((!token && !loading) || !identity) {
    return <Login setToken={setToken} />;
  }

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        position="absolute"
        height="100%"
        width="100%"
      >
        <Spinner size="sizeIcon110" decorative={false} title="Loading" />
      </Box>
    );
  }

  return <AppContainer />;
}

export default App;
