import { UserIcon } from "@twilio-paste/icons/esm/UserIcon";
import { Avatar } from "@twilio-paste/avatar";
import { Text } from "@twilio-paste/core";
import { Menu, MenuButton, useMenuState, MenuItem } from "@twilio-paste/menu";
import { ChevronDownIcon } from "@twilio-paste/icons/esm/ChevronDownIcon";
import React, { useMemo } from "react";
import styles from "../styles";
import { ConnectionState } from "@twilio/conversations";
import { useTranslation } from "react-i18next";

type AppHeaderProps = {
  user: string;
  onSignOut: () => void;
  connectionState: ConnectionState;
};
const AppHeader: React.FC<AppHeaderProps> = ({
  user,
  onSignOut,
  connectionState,
}) => {
  const { t } = useTranslation();

  const menu = useMenuState();

  const label: "online" | "connecting" | "offline" = useMemo(() => {
    switch (connectionState) {
      case "connected":
        return t("online");
      case "connecting":
        return t("connecting");
      default:
        return t("offline");
    }
  }, [connectionState]);

  return (
    <div style={styles.appHeader}>
      <div style={styles.flex}>
        {/*<div style={styles.appLogoWrapper}>*/}
        {/*  <LogoTwilioIcon*/}
        {/*    decorative={false}*/}
        {/*    color={"colorTextBrandHighlight"}*/}
        {/*    size={"sizeIcon40"}*/}
        {/*    title="app logo"*/}
        {/*  />*/}
        {/*</div>*/}
        <div style={styles.appLogoTitle}>Clik Conversations</div>
      </div>
      <div style={styles.userTile}>
        <Avatar size="sizeIcon70" name="avatar example" icon={UserIcon} />
        <div
          style={{
            padding: "0 10px",
          }}
        >
          <Text as="span" style={styles.userName}>
            {user}
          </Text>
          <Text
            as="span"
            color={
              label === "online"
                ? "colorTextPrimaryWeak"
                : label === "connecting"
                ? "colorTextIconBusy"
                : "colorTextWeaker"
            }
            style={styles.userStatus}
          >
            {label === "online"
              ? t("online_upper")
              : label === "connecting"
              ? t("connecting") + "..."
              : t("offline")}
          </Text>
        </div>
        <MenuButton {...menu} variant="link" size="reset">
          <ChevronDownIcon
            color="colorTextInverse"
            decorative={false}
            title="Settings"
          />
        </MenuButton>
        <Menu {...menu} aria-label="Preferences">
          <MenuItem {...menu} onClick={onSignOut}>
            {t("sign_out")}
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};

export default AppHeader;
