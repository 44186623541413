import { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useSearchParams,
} from "react-router-dom";
import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import translationEN from "./locales/en/translation.json"; // Import translations
import translationVI from "./locales/vi/translation.json"; // Import translations

import Client from "./pages/client";
import Admin from "./admin";

export const LanguageWrapper = () => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    let language = searchParams.get("language");

    if (!language) {
      language = "vi";
    }

    if (language !== "vi") {
      language = "en";
    }

    i18n.use(initReactI18next).init({
      resources: {
        en: { translation: translationEN },
        vi: { translation: translationVI },
      },
      lng: language,
      fallbackLng: language,
      interpolation: {
        escapeValue: false,
      },
    });

    console.log("init language", language);
  }, []);
};

const AppRouter = () => {
  return (
    <>
      <Router>
        <LanguageWrapper />
        <Routes>
          <Route exact path="/message" Component={Client} />
          <Route exact path="/admin" Component={Admin} />
        </Routes>
      </Router>
    </>
  );
};

export default AppRouter;
