import React from "react";
import { Theme } from "@twilio-paste/core/theme";
import { Box } from "@twilio-paste/core";
import { Provider } from "react-redux";

import ClientAppContainer from "../../component/ClientAppContainer";
import { store } from "../../admin/store";

const ClientPage = () => {
  return (
    <Box className="customer-page">
      <Provider store={store}>
        <Theme.Provider theme="Clik">
          <Box>
            <ClientAppContainer />
          </Box>
        </Theme.Provider>
      </Provider>
    </Box>
  );
};

export default ClientPage;
