import React, { useState } from "react";
import { Select } from "antd";
import type { SelectProps } from "antd";

import { searchUser } from "../../api";

const SearchInput: React.FC<{
  placeholder: string;
  style: React.CSSProperties;
  onChange: (value: string) => void;
}> = (props) => {
  const [data, setData] = useState<SelectProps["options"]>([]);
  const [value, setValue] = useState<string>();

  const handleSearch = async (newValue: string) => {
    if (newValue) {
      const users: any[] = await searchUser(newValue);

      const options = users.map((user) => {
        return {
          label: `${user.name} - ${user.email}`,
          value: user.code,
        };
      });
      setData(options ?? []);
    } else {
      setData([]);
    }
  };

  const handleChange = (newValue: string) => {
    setValue(newValue);
    console.log(newValue);
    props.onChange(newValue);
  };

  return (
    <Select
      style={{ width: "100%" }}
      showSearch
      value={value}
      defaultActiveFirstOption={false}
      showArrow={false}
      filterOption={false}
      onSearch={handleSearch}
      onChange={handleChange}
      notFoundContent={null}
      options={(data || []).map((d) => ({
        value: d.value,
        label: d.label,
      }))}
    />
  );
};

type Props = {
  onChange: (value: string) => void;
};

const SearchBox = (props: Props) => (
  <SearchInput
    placeholder="input search text"
    style={{ width: 200 }}
    {...props}
  />
);

export default SearchBox;
