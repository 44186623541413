import React from "react";
import { Provider } from "react-redux";
import { store } from "./store";
import { Theme } from "@twilio-paste/core/theme";
import { Box } from "@twilio-paste/core";

import App from "./components/App";
import styles from "../admin/styles";

const Admin = () => {
  return (
    <Box style={styles.app} className="admin-page">
      <Provider store={store}>
        <Theme.Provider theme="Clik">
          <Box style={styles.app}>
            <App />
          </Box>
        </Theme.Provider>
      </Provider>
    </Box>
  );
};

export default Admin;
