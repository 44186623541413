import {
  Anchor,
  Box,
  ModalBody,
  Table,
  TBody,
  Td,
  Th,
  THead,
  Tr,
} from "@twilio-paste/core";
import { MenuButton, Menu, MenuItem, useMenuState } from "@twilio-paste/menu";
import { Text } from "@twilio-paste/text";
import { ChevronDownIcon } from "@twilio-paste/icons/esm/ChevronDownIcon";
import { Avatar } from "@twilio-paste/avatar";
import { UserIcon } from "@twilio-paste/icons/cjs/UserIcon";

import ConvoModal from "./ConvoModal";
import { Content } from "../../types";
import { ReduxParticipant } from "../../store/reducers/participantsReducer";
import { getIdentity } from "../../../utils/storage.utils";
import { useEffect, useState } from "react";
import { getAllUserInfo } from "../../api";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators, AppState } from "../../store";
import { UserInfo } from "../../store/reducers/userInfoReducer";

interface ManageParticipantsModalProps {
  participantsCount: number;
  handleClose: () => void;
  isModalOpen: boolean;
  title: string;
  onClick: (content: Content) => void;
  participantsList: ReduxParticipant[];
  onParticipantRemove: (participant: ReduxParticipant) => void;
}

const ManageParticipantsModal: React.FC<ManageParticipantsModalProps> = (
  props: ManageParticipantsModalProps
) => {
  const menu = useMenuState({ placement: "bottom-start" });

  const { t } = useTranslation();

  const userInfo = useSelector((state: AppState) => state.userInfo);

  const dispatch = useDispatch();
  const { addParticipantInfo } = bindActionCreators(actionCreators, dispatch);

  useEffect(() => {
    getFriendlyNameOfParticipants();
  }, [props.participantsList]);

  const getFriendlyNameOfParticipants = async () => {
    console.log("props.participantsList", props.participantsList);

    const identities = props.participantsList.map(
      (participant) => participant.identity ?? ""
    );

    const unknownIdentities: string[] = identities.filter(
      (identity) => !userInfo?.participantById?.[identity]
    );

    if (unknownIdentities?.length > 0) {
      const unknownUsers: UserInfo[] = await getAllUserInfo(unknownIdentities);

      const userMapByCode = unknownUsers.reduce(
        (result: { [key: string]: UserInfo }, item) => {
          result[item.code] = item;
          return result;
        },
        {}
      );

      console.log("unknownIdentities", unknownIdentities);

      unknownIdentities.forEach((unknownIdentity) => {
        let userInfo: UserInfo;
        if (userMapByCode[unknownIdentity]) {
          userInfo = userMapByCode[unknownIdentity];
        } else {
          userInfo = {
            name: unknownIdentity,
            email: "",
            code: unknownIdentity,
          };
        }
        addParticipantInfo(userInfo);
      });
    }
  };

  const capitalizedString = (word: string): string => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  };

  console.log(
    userInfo?.participantById,
    "hello",
    userInfo?.participantById?.["f63bb42c-8eb4-4e96-ab87-76e1690dfadf"]
  );

  return (
    <>
      <ConvoModal
        handleClose={() => props.handleClose()}
        isModalOpen={props.isModalOpen}
        title={props.title}
        modalBody={
          <ModalBody>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                paddingTop: "6px",
              }}
            >
              <Box
                fontFamily="fontFamilyText"
                fontWeight="fontWeightBold"
                fontSize="fontSize30"
                lineHeight="lineHeight60"
              >
                {capitalizedString(`${t("participants")}`)} (
                {props.participantsCount})
              </Box>
              <MenuButton
                {...menu}
                variant="secondary"
                onClick={() => props.onClick(Content.AddChat)}
              >
                {capitalizedString(`${t("add")} ${t("participant")}`)}
                {/* <ChevronDownIcon decorative size="sizeIcon10" /> */}
              </MenuButton>
              {/* <Menu {...menu} aria-label="Preferences"> */}
              {/* <MenuItem
                  {...menu}
                  onClick={() => props.onClick(Content.AddSMS)}
                >
                  SMS Participant
                </MenuItem>
                <MenuItem
                  {...menu}
                  onClick={() => {
                    props.onClick(Content.AddWhatsApp);
                  }}
                >
                  WhatsApp Participant
                </MenuItem> */}
              {/* <MenuItem
                  {...menu}
                  onClick={() => {
                    props.onClick(Content.AddChat);
                  }}
                >
                  Chat Participant
                </MenuItem>
              </Menu> */}
            </Box>
            <Box
              style={{
                marginTop: "12px",
                overflow: "hidden",
                overflowY: "auto",
                maxHeight: "500px",
              }}
            >
              <Table>
                <THead hidden={true}>
                  <Tr>
                    <Th width="size10" style={{ width: "50px" }} />
                    <Th width="size40" textAlign="left" />
                    <Th textAlign="right" />
                  </Tr>
                </THead>
                <TBody>
                  {props.participantsList.length ? (
                    props.participantsList.map((participant: any) => (
                      <Tr key={participant.sid}>
                        <Td width="size20">
                          <Avatar
                            size="sizeIcon80"
                            name={
                              userInfo?.participantById?.[participant.identity]
                                ?.name ?? participant.identity
                            }
                            icon={UserIcon}
                          />
                        </Td>
                        <Td textAlign="left">
                          <Text as="span" textAlign="left">
                            {userInfo?.participantById?.[participant.identity]
                              ?.name ?? participant.identity}
                          </Text>
                        </Td>
                        <Td textAlign="right">
                          {participant.identity !== getIdentity() ? (
                            <Anchor
                              href="#"
                              onClick={() =>
                                props.onParticipantRemove(participant)
                              }
                            >
                              {t("remove")}
                            </Anchor>
                          ) : null}
                        </Td>
                      </Tr>
                    ))
                  ) : (
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "400px",
                      }}
                    >
                      <Box
                        style={{
                          color: "#606B85",
                        }}
                      >
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            paddingBottom: "12px",
                          }}
                        >
                          <UserIcon
                            decorative={false}
                            title="No participants"
                            size="sizeIcon40"
                            style={{
                              color: "#606B85",
                            }}
                          />
                        </Box>
                        <Text
                          as="p"
                          fontSize="fontSize40"
                          style={{
                            color: "#606B85",
                          }}
                        >
                          No participants
                        </Text>
                      </Box>
                    </Box>
                  )}
                </TBody>
              </Table>
            </Box>
          </ModalBody>
        }
      />
    </>
  );
};

export default ManageParticipantsModal;
