import axios from "axios";
import {
  Conversation,
  Message,
  Participant,
  Media,
  Client,
  Paginator,
} from "@twilio/conversations";

import {
  MessageStatus,
  ReduxMessage,
} from "./store/reducers/messageListReducer";
import {
  CONVERSATION_MESSAGES,
  CONVERSATION_PAGE_SIZE,
  PARTICIPANT_MESSAGES,
} from "./constants";
import { NotificationsType } from "./store/reducers/notificationsReducer";
import { successNotification, unexpectedErrorNotification } from "./helpers";
import { getSdkMessageObject } from "../conversations-objects";
import { ReduxParticipant } from "./store/reducers/participantsReducer";
import { getAuthToken, getIdentity } from "../utils/storage.utils";
import { UserInfo } from "./store/reducers/userInfoReducer";

const API_SERVICE_HOST = process.env.REACT_APP_API_SERVICE_HOST;

type ParticipantResponse = ReturnType<typeof Conversation.prototype.add>;

export async function addConversation(
  friendlyName: string,
  updateParticipants: (participants: Participant[], sid: string) => void,
  client?: Client,
  addNotifications?: (notifications: NotificationsType) => void
): Promise<Conversation> {
  if (client === undefined) {
    throw new Error(
      "Client is suddenly undefined, are you sure everything is ok?"
    );
  }

  if (friendlyName.length === 0) {
    throw new Error("Conversation name is empty");
  }

  try {
    const conversation = await client.createConversation({
      friendlyName,
    });
    await conversation.join();

    const participants = await conversation.getParticipants();
    updateParticipants(participants, conversation.sid);

    successNotification({
      message: CONVERSATION_MESSAGES.CREATED,
      addNotifications,
    });

    return conversation;
  } catch (e) {
    unexpectedErrorNotification(e.message, addNotifications);
    throw e;
  }
}

export async function addChatParticipant(
  name: string,
  convo?: Conversation,
  addNotifications?: (notifications: NotificationsType) => void
) {
  if (convo === undefined || !convo?.uniqueName) {
    throw new Error(
      "Conversation is suddenly undefined, are you sure everything is ok?"
    );
  }

  if (name.length === 0) {
    throw new Error("Participant name is empty");
  }

  try {
    console.log("add participant", convo, name);

    await addUserToConversation(convo.uniqueName, name);

    successNotification({
      message: PARTICIPANT_MESSAGES.ADDED,
      addNotifications,
    });
  } catch (e) {
    unexpectedErrorNotification(e.message, addNotifications);
    throw e;
  }
}

export async function addNonChatParticipant(
  number: string,
  proxyNumber: string,
  convo?: Conversation,
  addNotifications?: (notifications: NotificationsType) => void
): Promise<ParticipantResponse> {
  if (convo === undefined) {
    throw new Error(
      "Conversation is suddenly undefined, are you sure everything is ok?"
    );
  }

  if (number.length === 0 || proxyNumber.length === 0) {
    throw new Error(
      "Both participant number and proxy number must be specified"
    );
  }

  try {
    const result = await convo.addNonChatParticipant(proxyNumber, number, {
      friendlyName: number,
    });
    successNotification({
      message: PARTICIPANT_MESSAGES.ADDED,
      addNotifications,
    });

    return result;
  } catch (e) {
    unexpectedErrorNotification(e.message, addNotifications);
    throw e;
  }
}

export const getAdminToken = async (): Promise<string> => {
  const userChatCode = getIdentity();
  const url = `${API_SERVICE_HOST}/ext/v1/token-service`;
  try {
    const response = await axios.get(url, {
      params: { identity: userChatCode },
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 401) {
      throw new Error(error.response.data ?? "Authentication error.");
    }

    console.error(`ERROR received from ${url}: ${error}\n`);
    throw new Error(`ERROR received from ${url}: ${error}\n`);
  }
};

export async function getToken(
  username: string,
  password: string,
  isPublic = false
): Promise<string> {
  let requestAddress = process.env.REACT_APP_ACCESS_TOKEN_SERVICE_URL as string;
  if (isPublic) {
    requestAddress = process.env
      .REACT_APP_CLIENT_ACCESS_TOKEN_SERVICE_URL as string;
  }
  if (!requestAddress) {
    throw new Error(
      "REACT_APP_ACCESS_TOKEN_SERVICE_URL is not configured, cannot login"
    );
  }

  try {
    const response = await axios.get(requestAddress, {
      params: { identity: username, password: password },
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 401) {
      throw new Error(error.response.data ?? "Authentication error.");
    }

    console.error(`ERROR received from ${requestAddress}: ${error}\n`);
    throw new Error(`ERROR received from ${requestAddress}: ${error}\n`);
  }
}

export async function getMessageStatus(
  message: ReduxMessage,
  channelParticipants: ReduxParticipant[]
): Promise<{
  [MessageStatus.Delivered]?: number;
  [MessageStatus.Read]?: number;
  [MessageStatus.Failed]?: number;
  [MessageStatus.Sending]?: number;
}> {
  // FIXME should be: return statuses[message.sid];
  // after this modification:
  // message.on("updated", ({ message, updateReasons }) => {
  // if reason includes "deliveryReceipt" {
  //   // paginate detailed receipts
  //   const receipts = await message.getDetailedDeliveryReceipts(); // paginated backend query every time
  // }
  // });

  const statuses = {
    [MessageStatus.Delivered]: 0,
    [MessageStatus.Read]: 0,
    [MessageStatus.Failed]: 0,
    [MessageStatus.Sending]: 0,
  };

  if (message.index === -1) {
    return Promise.resolve({
      ...statuses,
      [MessageStatus.Sending]: 1,
    });
  }

  channelParticipants.forEach((participant) => {
    if (participant.identity == getIdentity() || participant.type !== "chat") {
      return;
    }

    if (
      participant.lastReadMessageIndex &&
      participant.lastReadMessageIndex >= message.index
    ) {
      statuses[MessageStatus.Read] += 1;
    } else if (participant.lastReadMessageIndex !== -1) {
      // statuses[MessageStatus.Delivered] += 1; FIXME don't need Delivered status for chat particpants?
    }
  });

  if (message.aggregatedDeliveryReceipt) {
    const sdkMessage = getSdkMessageObject(message);
    const receipts = await sdkMessage.getDetailedDeliveryReceipts(); // paginated backend query every time

    receipts.forEach((receipt) => {
      if (receipt.status === "read") {
        statuses[MessageStatus.Read] += 1;
      }

      if (receipt.status === "delivered") {
        statuses[MessageStatus.Delivered] += 1;
      }

      if (receipt.status === "failed" || receipt.status === "undelivered") {
        statuses[MessageStatus.Failed] += 1;
      }

      if (receipt.status === "sent" || receipt.status === "queued") {
        statuses[MessageStatus.Sending] += 1;
      }
    });
  }

  return statuses;
}

export const removeParticipant = async (
  conversation: Conversation,
  participant: Participant,
  addNotifications?: (notifications: NotificationsType) => void
): Promise<void> => {
  try {
    await conversation.removeParticipant(participant);
    successNotification({
      message: PARTICIPANT_MESSAGES.REMOVED,
      addNotifications,
    });
  } catch (e) {
    unexpectedErrorNotification(e.message, addNotifications);
    throw e;
  }
};

export const getBlobFile = async (
  media: Media,
  addNotifications?: (notifications: NotificationsType) => void
): Promise<Blob> => {
  try {
    const url = await getFileUrl(media);
    const response = await fetch(url);
    return response.blob();
  } catch (e) {
    unexpectedErrorNotification(e.message, addNotifications);
    throw e;
  }
};

export const getFileUrl = async (media: Media): Promise<string> => {
  return await media.getContentTemporaryUrl().then();
};

export const getMessages = async (
  conversation: Conversation
): Promise<Paginator<Message>> =>
  await conversation.getMessages(CONVERSATION_PAGE_SIZE);

export const adminLogin = async (username: string, password: string) => {
  const data = JSON.stringify({
    username,
    password,
  });

  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${API_SERVICE_HOST}/ext/v1/auth/login`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  const response = await axios.request(config);

  return response?.data;
};

export const getUserInfo = async () => {
  const authToken = getAuthToken();
  const identity = getIdentity();
  const config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${API_SERVICE_HOST}/users/${identity}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    data: {},
  };

  const response = await axios.request(config);

  return response?.data;
};

export const getAllUserInfo = async (
  identities: string[]
): Promise<UserInfo[]> => {
  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${API_SERVICE_HOST}/ext/v1/users/by-code-in`,
    headers: {
      "Content-Type": "application/json",
    },
    data: { identities },
  };

  const response = await axios.request(config);

  return response?.data;
};

export const searchUser = async (keyword: string) => {
  const authToken = getAuthToken();
  const config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${API_SERVICE_HOST}/users?keyword=${keyword}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  };

  const response = await axios.request(config);

  return response?.data;
};

export const addUserToConversation = async (
  conversationCode: string,
  userCode: string
) => {
  const authToken = getAuthToken();
  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${API_SERVICE_HOST}/users/addUserToConversation`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    data: JSON.stringify({
      conversationCode,
      userCode,
    }),
  };

  await axios.request(config);
};
