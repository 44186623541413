import { ActionType } from "../action-types";
import { Action } from "../actions";

export type UserInfo = {
  name: string;
  code: string;
  email: string;
};

export type ReduxUserInfo = {
  customer?: UserInfo;
  participants?: string[];
  participantById?: { [key: string]: UserInfo };
};

const initialState: ReduxUserInfo = {};

const reducer = (
  state: ReduxUserInfo = initialState,
  action: Action
): ReduxUserInfo => {
  switch (action.type) {
    case ActionType.SAVE_USER_INFO:
      return {
        ...state,
        customer: {
          ...action.payload,
        },
        participants: Array.from(
          new Set([...(state.participants ?? []), action.payload.code])
        ),
        participantById: {
          ...state.participantById,
          [action.payload.code]: action.payload,
        },
      };
    case ActionType.SAVE_PARTICIPANT_INFO:
      return {
        ...state,
        participants: Array.from(
          new Set([...(state.participants ?? []), action.payload.code])
        ),
        participantById: {
          ...state.participantById,
          [action.payload.code]: action.payload,
        },
      };
    default:
      return state;
  }
};

export default reducer;
