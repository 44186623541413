import {
  AGENT,
  CONVERSATION,
  IDENTITY,
  PASSWORD,
  USERNAME,
} from "../constant/storage-variable";

export const setAgent = (agent: string) => {
  localStorage.setItem(AGENT, agent);
};
export const getAgent = () => {
  return localStorage.getItem(AGENT);
};
export const setIdentity = (identity: string) => {
  localStorage.setItem(IDENTITY, identity);
};
export const getIdentity = () => {
  return localStorage.getItem(IDENTITY);
};
export const removeIdentity = () => {
  localStorage.removeItem(IDENTITY);
};
export const setPassword = (password: string) => {
  localStorage.setItem(PASSWORD, password);
};
export const getPassword = () => {
  return localStorage.getItem(PASSWORD);
};
export const removePassword = () => {
  localStorage.removeItem(PASSWORD);
};
export const setConversation = (conversation: string) => {
  localStorage.setItem(CONVERSATION, conversation);
};
export const getConversation = () => {
  return localStorage.getItem(CONVERSATION);
};

export const removeConversation = () => {
  localStorage.removeItem(CONVERSATION);
};

export const setAuthToken = (authToken: string) => {
  localStorage.setItem("auth_token", authToken);
};

export const getAuthToken = () => {
  return localStorage.getItem("auth_token");
};
