import { FC, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Client,
  ConnectionState,
  Message,
  Participant,
} from "@twilio/conversations";
import { Box, Spinner } from "@twilio-paste/core";

import { actionCreators, AppState } from "../../admin/store";

import {
  addChatParticipant,
  addConversation,
  getConversationBySid,
} from "../../services/chat.services";
import MessagesBox from "../message/MessagesBox";
import MessageInputField from "../message/MessageInputField";
import { handlePromiseRejection } from "../../helpers";
import { AddMessagesType } from "../../types/common.types";
import ConversationDetails from "../conversations/ConversationDetails";
import {
  getClientConversation,
  getClientToken,
} from "../../services/conversation.service";
import {
  getAgent,
  getConversation,
  getIdentity,
  removeConversation,
  setAgent,
  setConversation,
} from "../../utils/storage.utils";
import { sleep } from "../../utils/functions.utils";

const SingleChatBox: FC = () => {
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();

  const [connectionState, setConnectionState] = useState<ConnectionState>();
  const [client, setClient] = useState<Client>();
  const [clientIteration, setClientIteration] = useState(0);
  const [conversationEntity, setConversationEntity] = useState(null);

  const token = useSelector((state: AppState) => state.token);
  const conversations = useSelector((state: AppState) => state.convos);
  const sid = useSelector((state: AppState) => state.sid);
  const loadingStatus = useSelector((state: AppState) => state.loadingStatus);
  const participants =
    useSelector((state: AppState) => state.participants)[sid] ?? [];
  const messages = useSelector((state: AppState) => state.messages);
  const typingData =
    useSelector((state: AppState) => state.typingData)[sid] ?? [];
  const lastReadIndex = useSelector((state: AppState) => state.lastReadIndex);

  const conversation = useMemo(
    () => conversations.find((convo) => convo.sid === sid),
    [sid, conversations]
  );

  const identity = getIdentity();

  const {
    setUserLanguage,
    upsertMessages,
    pushMessages,
    updateParticipants,
    startTyping,
    endTyping,
    upsertConversation,
    login,
    updateCurrentConversation,
    addNotifications,
    clearAttachments,
    removeMessages,
  } = bindActionCreators(actionCreators, dispatch);

  const joinConversation = async () => {
    const buildingCode: string = searchParams.get("buildingCode") || "";
    await getClientConversation(buildingCode).then(async (conv) => {
      console.log("conv", conv);
      setConversationEntity(conv);
      console.info(
        "join conversation: ",
        client?.connectionState,
        conv.conversationTwilioSID
      );
      const convo = await getConversationBySid(
        conv.conversationTwilioSID,
        updateParticipants,
        client,
        addNotifications
      );
      setConversation(convo.sid);
      updateCurrentConversation(convo.sid);
    });
  };

  const updateTypingIndicator = (
    participant: Participant,
    sid: string,
    callback: (sid: string, user: string) => void
  ) => {
    const {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      attributes: { friendlyName },
      identity,
    } = participant;
    if (identity === getIdentity()) {
      return;
    }
    callback(sid, identity || friendlyName || "");
  };

  const upsertMessage = async (
    message: Message,
    upsertMessages: AddMessagesType
  ) => {
    //transform the message and add it to redux
    await handlePromiseRejection(async () => {
      await message.conversation.advanceLastReadMessageIndex(message.index);
      upsertMessages(message.conversation.sid, [message]);
    }, addNotifications);
  };

  useEffect(() => {
    const language = localStorage.getItem("chat-language");
    if (language) {
      setUserLanguage(language);
    }
  }, []);

  useEffect(() => {
    console.log(connectionState);
    if (connectionState === "connected") {
      joinConversation();
    }
  }, [connectionState]);

  useEffect(() => {
    const client = new Client(token);
    setClient(client);

    client.on("conversationJoined", (conversation) => {
      console.log("sid: ", conversation.sid);
      upsertConversation(conversation);
      conversation.on("typingStarted", (participant) => {
        handlePromiseRejection(
          () =>
            updateTypingIndicator(participant, conversation.sid, startTyping),
          addNotifications
        );
      });
      conversation.on("typingEnded", async (participant) => {
        await handlePromiseRejection(
          async () =>
            updateTypingIndicator(participant, conversation.sid, endTyping),
          addNotifications
        );
      });
      handlePromiseRejection(async () => {
        if (conversation.status === "joined") {
          const result = await conversation.getParticipants();
          updateParticipants(result, conversation.sid);
          const messages = await conversation.getMessages();
          upsertMessages(conversation.sid, messages.items);
        }
      }, addNotifications);
    });
    client.on("messageAdded", async (message: Message) => {
      await upsertMessage(message, upsertMessages);
      if (message.author === identity) {
        clearAttachments(message.conversation.sid, "-1");
      }
    });
    client.on("messageUpdated", async ({ message }) => {
      await handlePromiseRejection(
        async () => upsertMessage(message, upsertMessages),
        addNotifications
      );
    });
    client.on("messageRemoved", async (message) => {
      await handlePromiseRejection(
        () => removeMessages(message.conversation.sid, [message]),
        addNotifications
      );
    });
    client.on("tokenAboutToExpire", async () => {
      if (identity) {
        const token = await getClientToken();
        await client.updateToken(token);
        login(token);
      }
    });
    client.on("tokenExpired", async () => {
      if (identity) {
        const token = await getClientToken();
        login(token);
        setClientIteration((x) => x + 1);
      }
    });
    client.on("connectionStateChanged", (state) => {
      setConnectionState(state);
    });

    return () => {
      client?.removeAllListeners();
    };
  }, [clientIteration]);

  return conversation && client ? (
    <Box
      display="flex"
      flexDirection="column"
      position="absolute"
      top="0"
      left="0"
      bottom="0"
      right="0"
    >
      <ConversationDetails
        convoSid={sid}
        convo={conversation}
        participants={participants}
      />

      <MessagesBox
        key={sid}
        convoSid={sid}
        convo={conversation}
        upsertMessage={pushMessages}
        client={client}
        messages={messages[sid]}
        loadingState={loadingStatus}
        participants={participants}
        lastReadIndex={lastReadIndex}
      />

      <MessageInputField
        convoSid={sid}
        client={client}
        messages={messages[sid]}
        convo={conversation}
        typingData={typingData}
      />
    </Box>
  ) : (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="absolute"
      height="100%"
      width="100%"
    >
      <Spinner size="sizeIcon110" decorative={false} title="Loading" />
    </Box>
  );
};

export default SingleChatBox;
