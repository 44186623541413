import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

import { Input, Switch } from "antd";
import { SendOutlined } from "@ant-design/icons";
import ReactFlagsSelect from "react-flags-select";

import { Client } from "@twilio/conversations";
import { AttachIcon } from "@twilio-paste/icons/esm/AttachIcon";
import { Box, Button } from "@twilio-paste/core";
import { useTheme } from "@twilio-paste/theme";
import { Text } from "@twilio-paste/text";

import { MAX_FILE_SIZE } from "../../constants";
import { getTypingMessage, unexpectedErrorNotification } from "../../helpers";

import { AppState, actionCreators } from "../../admin/store";
import { ReduxConversation } from "../../admin/store/reducers/convoReducer";
import { ReduxMessage } from "../../admin/store/reducers/messageListReducer";
import { getSdkConversationObject } from "../../conversations-objects";
import { checkAgentResponseTimeout } from "../../services/chat.services";

import "./styles/messageInput.css";
import { getIdentity } from "../../utils/storage.utils";
import { useTranslation } from "react-i18next";
import { getAllCountries } from "../../utils/languageCountryConverter";

interface SendMessageProps {
  convoSid: string;
  client: Client;
  messages: ReduxMessage[];
  convo: ReduxConversation;
  typingData: string[];
}

const MessageInputField: React.FC<SendMessageProps> = (
  props: SendMessageProps
) => {
  const { t } = useTranslation();

  const userLanguage = useSelector((state: AppState) => state.userLanguage);

  const [message, setMessage] = useState("");
  const [files, setFiles] = useState<File[]>([]);
  // needed to clear input type=file
  const [filesInputKey, setFilesInputKey] = useState<string>("input-key");

  const theme = useTheme();
  const typingInfo = getTypingMessage(props.typingData);

  const dispatch = useDispatch();
  const { setUserLanguage, addNotifications } = bindActionCreators(
    actionCreators,
    dispatch
  );

  useEffect(() => {
    setMessage("");
    setFiles([]);
    setFilesInputKey(Date.now().toString());
  }, [props.convo]);

  useEffect(() => {
    if (!files.length) {
      setFilesInputKey(Date.now().toString());
    }
  }, [files]);

  const sdkConvo = useMemo(
    () => getSdkConversationObject(props.convo),
    [props.convo.sid]
  );

  const onLanguageChange = (language: string) => {
    setUserLanguage(language);
    localStorage.setItem("chat-language", language);
  };

  const onFilesChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { files: assets } = event.target;
    if (!assets?.length) {
      return;
    }

    const validFiles = Array.from(assets).filter(
      ({ size }) => size < MAX_FILE_SIZE + 1
    );

    if (validFiles.length < assets.length) {
      // TODO: show error
    }

    setFiles([...files, ...validFiles]);
  };

  const onFileRemove = (file: string) => {
    const fileIdentityArray = file.split("_");
    const fileIdentity = fileIdentityArray
      .slice(0, fileIdentityArray.length - 1)
      .join();
    const existentFiles = files.filter(
      ({ name, size }) =>
        name !== fileIdentity &&
        size !== Number(fileIdentityArray[fileIdentityArray.length - 1])
    );

    setFiles(existentFiles);
  };

  const handleMessageNavigation = async () => {
    try {
      const { convo } = props;
      checkAgentResponseTimeout(convo.sid, getIdentity() ?? "");
    } catch (ex) {
      console.log("checkAgentResponseTimeout fail", ex);
    }
    await onMessageSend();

    setMessage("");
  };

  const onMessageSend = async () => {
    if (message.length == 0 && files.length == 0) {
      return;
    }

    const { convo } = props;
    const sdkConvo = getSdkConversationObject(convo);

    const newMessageBuilder = sdkConvo.prepareMessage().setBody(message);

    // const newMessage: ReduxMessage = {
    //   author: client.user.identity,
    //   body: message,
    //   attributes: {},
    //   dateCreated: currentDate,
    //   index: -1,
    //   participantSid: "",
    //   sid: "-1",
    //   aggregatedDeliveryReceipt: null,
    //   attachedMedia: [],
    // } as ReduxMessage;

    for (const file of files) {
      const fileData = new FormData();
      fileData.set(file.name, file, file.name);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // newMessage.attachedMedia.push({
      //   sid: key + "",
      //   size: file.size,
      //   filename: file.name,
      //   contentType: file.type,
      // });
      // addAttachment(convo.sid, "-1", key + "", file);
      newMessageBuilder.addMedia(fileData);
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // upsertMessages(convo.sid, [newMessage]);
    setMessage("");
    setFiles([]);
    const messageIndex = await newMessageBuilder.build().send();

    try {
      await sdkConvo.advanceLastReadMessageIndex(messageIndex ?? 0);
    } catch (e) {
      unexpectedErrorNotification(e.message, addNotifications);
      throw e;
    }
  };

  return (
    <Box
      display="flex"
      flexBasis="60px"
      flexGrow={10}
      flexDirection="column"
      borderTopStyle="solid"
      borderTopWidth="borderWidth10"
      style={{
        borderTopColor: theme.borderColors.colorBorderWeak,
        backgroundColor: theme.backgroundColors.colorBackgroundBody,
      }}
    >
      <Box
        paddingBottom="space50"
        paddingTop="space50"
        paddingLeft="space150"
        hidden={!props.typingData.length}
      >
        <Text as="p" color="colorTextIcon">
          {typingInfo}
        </Text>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        height="100%"
        flexGrow={10}
        paddingBottom="space30"
        paddingTop="space30"
      >
        <Box
          display="flex"
          height="100%"
          flexBasis="space40"
          flexDirection="row"
          alignItems="center"
          justifyItems="center"
          padding="space10"
        >
          <ReactFlagsSelect
            selected={userLanguage}
            countries={getAllCountries()}
            showSelectedLabel={false}
            showOptionLabel={false}
            onSelect={onLanguageChange}
            className="flags-select"
          />
        </Box>
        {/* <Box
          display="flex"
          height="100%"
          flexBasis="space40"
          flexDirection="row"
          alignItems="center"
          justifyItems="center"
          padding="space10"
        >
          <Switch
            checkedChildren="AI"
            unCheckedChildren="Agent"
            checked={isChatWithAI}
            onChange={setChatWithAI}
          />
        </Box> */}
        <Box
          flexBasis="50px"
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
        >
          <Button variant="link">
            <label htmlFor="file-input" style={{ cursor: "pointer" }}>
              <AttachIcon
                decorative={true}
                title="Attach file"
                size="sizeIcon50"
              />
            </label>
            <input
              id="file-input"
              key={filesInputKey}
              type="file"
              style={{ display: "none", cursor: "pointer" }}
              onChange={onFilesChange}
            />
          </Button>
        </Box>
        <Box paddingRight="space20" flexGrow={10}>
          {/* <MessageInput
            assets={files}
            message={message}
            onChange={(e: string) => {
              sdkConvo.typing();
              setMessage(e);
            }}
            onEnterKeyPress={handleMessageNavigation}
            onFileRemove={onFileRemove}
          /> */}
          <Input
            style={{ height: "100%" }}
            placeholder={t("input_placeholder")}
            autoFocus
            value={message}
            onChange={(e) => {
              sdkConvo.typing();
              setMessage(e.target.value);
            }}
            onPressEnter={handleMessageNavigation}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width="40px"
        >
          {/* <SendMessageButton
            message={message}
            onClick={handleMessageNavigation}
          /> */}
          <Button variant="link">
            <SendOutlined
              style={{ fontSize: "20px", marginTop: "-8px" }}
              rotate={315}
              onClick={handleMessageNavigation}
            />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default MessageInputField;
