import React, { ReactNode, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { saveAs } from "file-saver";

import {
  ChatLog,
  ChatMessage,
  ChatMessageMeta,
  ChatMessageMetaItem,
  ChatBubble,
} from "@twilio-paste/core";

import { Spin } from "antd";
import moment, { Moment } from "moment";

import type { ReactionsType } from "./Reactions";
import MessageMedia from "./MessageMedia";

import TimeAgo from "javascript-time-ago";
import { MessageStatus } from "./MessageStatus";
import { MAX_MESSAGE_LINE_WIDTH } from "../../constants";
import wrap from "word-wrap";

import { getAllUserInfo, getBlobFile } from "../../admin/api";
import ImagePreviewModal from "../../admin/components/modals/ImagePreviewModal";
import { AppState, actionCreators } from "../../admin/store";
import { ReduxConversation } from "../../admin/store/reducers/convoReducer";
import {
  ReduxMessage,
  ReduxMedia,
} from "../../admin/store/reducers/messageListReducer";
import { ReduxParticipant } from "../../admin/store/reducers/participantsReducer";
import {
  getSdkConversationObject,
  getSdkMediaObject,
} from "../../conversations-objects";

import "./styles/messageList.css";
import { getIdentity } from "../../utils/storage.utils";
import { Message } from "@twilio/conversations";
import { useTranslation } from "react-i18next";
import { translateByUserLanguage } from "../../services/chat.services";
import { UserInfo } from "../../admin/store/reducers/userInfoReducer";

interface MessageListProps {
  messages: ReduxMessage[];
  conversation: ReduxConversation;
  participants: ReduxParticipant[];
  lastReadIndex: number;
}

interface TranslateMessageType {
  sid: string;
  original: string;
  translated: {
    language: string;
    translatedText: string;
  };
  isShowOriginal: boolean;
}

interface TranslationMap {
  [key: string]: TranslateMessageType;
}

function getMessageTime(message: ReduxMessage) {
  const dateCreated = message.dateCreated;

  return dateCreated
    ? new TimeAgo("en-US").format(dateCreated, "twitter-now")
    : "";
}

const MetaItemWithMargin: React.FC<{ children: ReactNode }> = (props) => (
  <ChatMessageMetaItem>
    <div style={{ marginTop: "5px" }}>{props.children}</div>
  </ChatMessageMetaItem>
);

const MetaItemAuthor: React.FC<any> = (props) => {
  const { isOutbound, message, friendlyName } = props;
  return (
    <div>
      {isOutbound
        ? `${getMessageTime(message)}`
        : `${friendlyName ?? message.author}・${getMessageTime(message)}`}
    </div>
  );
};

const MessageList: React.FC<MessageListProps> = (props: MessageListProps) => {
  const { t } = useTranslation();

  const [translateMessage, setTranslateMessage] = useState<TranslationMap>({});
  const [translating, setTranslating] = useState<string | null>();
  const [startDate] = useState<Moment>(moment());

  const userLanguage = useSelector((state: AppState) => state.userLanguage);
  const userInfo = useSelector((state: AppState) => state.userInfo);

  const lastMessageTime = useRef<Date>();

  const { messages, conversation, lastReadIndex } = props;
  if (messages === undefined) {
    return <div className="empty" />;
  }

  const myRef = useRef<HTMLInputElement>(null);

  const dispatch = useDispatch();
  const { addAttachment, addNotifications, addParticipantInfo } =
    bindActionCreators(actionCreators, dispatch);
  const conversationAttachments = useSelector(
    (state: AppState) => state.attachments[conversation.sid]
  );

  const [imagePreview, setImagePreview] = useState<{
    message: ReduxMessage;
    file: Blob;
    sid: string;
  } | null>(null);

  const [horizonMessageCount, setHorizonMessageCount] = useState<number>(0);

  useEffect(() => {
    myRef?.current?.scrollIntoView({
      behavior: "smooth",
    });
  });

  useEffect(() => {
    if (lastReadIndex === -1 || horizonMessageCount) {
      return;
    }
    // const showIndex = 0;
    getSdkConversationObject(conversation)
      .getUnreadMessagesCount()
      .then((count) => {
        setHorizonMessageCount(count ?? 0);
        // setShowHorizonIndex(showIndex);
      });
  }, [messages, lastReadIndex]);

  useEffect(() => {
    getFriendlyNameOfParticipants();
    translateFriendMessageAutomatic();
  }, [messages]);

  const getFriendlyNameOfParticipants = async () => {
    console.log("messages", messages);

    const identities = messages.map((message) => message.author ?? "");

    const unknownIdentities: string[] = identities.filter(
      (identity) => !userInfo?.participantById?.[identity]
    );

    if (unknownIdentities?.length > 0) {
      const unknownUsers: UserInfo[] = await getAllUserInfo(unknownIdentities);

      const userMapByCode = unknownUsers.reduce(
        (result: { [key: string]: UserInfo }, item) => {
          result[item.code] = item;
          return result;
        },
        {}
      );

      console.log("unknownIdentities", unknownIdentities);

      unknownIdentities.forEach((unknownIdentity) => {
        let userInfo: UserInfo;
        if (userMapByCode[unknownIdentity]) {
          userInfo = userMapByCode[unknownIdentity];
        } else {
          userInfo = {
            name: unknownIdentity,
            email: "",
            code: unknownIdentity,
          };
        }
        addParticipantInfo(userInfo);
      });
    }
  };

  const translateFriendMessageAutomatic = async () => {
    // dont retranslate
    // dont translate owner message
    messages
      .filter(
        (message) =>
          message.author !== getIdentity() &&
          !translateMessage[message.sid] &&
          moment(message.dateCreated).isAfter(startDate)
      )
      .forEach((message) => {
        console.log("translate", message);
        doTranslateMessage(message);
      });
  };

  const onDownloadAttachments = async (message: ReduxMessage) => {
    const attachedMedia = message.attachedMedia?.map(getSdkMediaObject);
    if (message.index === -1) {
      return undefined;
    }
    if (!attachedMedia?.length) {
      return new Error("No media attached");
    }

    for (const media of attachedMedia) {
      const blob = await getBlobFile(media, addNotifications);
      addAttachment(props.conversation.sid, message.sid, media.sid, blob);
    }

    return;
  };

  const onFileOpen = (file: Blob, { filename }: ReduxMedia) => {
    saveAs(file, filename ?? "");
  };

  const doTranslateMessage = async (message: ReduxMessage) => {
    setTranslating(message.sid);
    try {
      // if message is not translated, call api to translate
      // or the translated language has changed
      if (
        !translateMessage[message.sid] ||
        translateMessage[message.sid]?.translated?.language !== userLanguage
      ) {
        const translatedText = await translateByUserLanguage(
          message.body || "",
          userLanguage
        );
        translateMessage[message.sid] = {
          sid: message.sid,
          original: message.body || "",
          translated: {
            language: userLanguage,
            translatedText,
          },
          isShowOriginal: true,
        };
      } else {
        translateMessage[message.sid].isShowOriginal = true;
      }
      setTranslateMessage({ ...translateMessage });
    } finally {
      setTranslating(null);
    }
  };

  const hideOriginalMessage = (message: ReduxMessage) => {
    if (translateMessage[message.sid]) {
      translateMessage[message.sid].isShowOriginal = false;
    }

    setTranslateMessage({ ...translateMessage });
  };

  const showOriginalMessage = (message: ReduxMessage) => {
    if (translateMessage[message.sid]) {
      translateMessage[message.sid].isShowOriginal = true;
    }

    setTranslateMessage({ ...translateMessage });
  };

  return (
    <ChatLog>
      {messages.map((message, index) => {
        const messageImages: ReduxMedia[] = [];
        const messageFiles: ReduxMedia[] = [];
        (message.attachedMedia ?? []).forEach((file) => {
          const { contentType } = file;
          if (contentType.includes("image")) {
            messageImages.push(file);
            return;
          }
          messageFiles.push(file);
        });

        const isOutbound = message.author === getIdentity();

        let wrappedBody;
        // the message has translate text
        if (translateMessage[message.sid]) {
          wrappedBody = (
            <div>
              <div
                className={
                  !translateMessage[message.sid]?.isShowOriginal
                    ? "single-translate-text-wrapper"
                    : "translate-text-wrapper"
                }
              >
                {translateMessage[message.sid]?.translated?.translatedText}
              </div>
              {translateMessage[message.sid]?.isShowOriginal && (
                <div className="original-wrapper">
                  {translateMessage[message.sid]?.original}
                </div>
              )}
            </div>
          );
        } else {
          console.log("isOutbound", isOutbound);
          wrappedBody = (
            <div
              className={
                !isOutbound
                  ? "single-original-wrapper inbound"
                  : "single-original-wrapper"
              }
            >
              {message.body}
            </div>
          );
        }

        let metaItems = [
          <MetaItemWithMargin key={1}>
            <MessageStatus
              message={message}
              channelParticipants={props.participants}
            />
          </MetaItemWithMargin>,
          <MetaItemWithMargin key={2}>
            <MetaItemAuthor
              isOutbound={isOutbound}
              friendlyName={
                userInfo?.participantById?.[message?.author ?? ""]?.name ??
                message?.author
              }
              message={message}
            />
          </MetaItemWithMargin>,
        ];

        if (isOutbound) {
          metaItems = metaItems.reverse();
        }

        const renderTimeline = (message: ReduxMessage) => {
          const differentTimeWithMessageBefore = moment(
            message.dateCreated
          ).diff(lastMessageTime.current, "seconds");

          lastMessageTime.current = message.dateCreated || new Date();
          // show timeline at the first message
          // show timeline if the message if over 15 minutes with next before message
          if (index === 0 || differentTimeWithMessageBefore > 60 * 15) {
            return (
              <div className="timeline" key={message.index}>
                {moment(message?.dateCreated)
                  ?.format("HH:mm - DD MMM yyyy")
                  ?.toUpperCase()}
              </div>
            );
          }

          return null;
        };

        const isShowButtonShowOriginal = (message: ReduxMessage): boolean => {
          // show original when message has translate message
          // and the original has hide
          if (translateMessage[message.sid]) {
            return translateMessage[message.sid]?.isShowOriginal === false;
          }

          return false;
        };

        const isShowButtonHideOriginal = (message: ReduxMessage) => {
          if (translateMessage[message.sid]) {
            return translateMessage[message.sid]?.isShowOriginal === true;
          }
          return false;
        };

        const isShowButtonTranslate = (message: ReduxMessage) => {
          return (
            translateMessage[message.sid]?.translated?.language !== userLanguage
          );
        };

        return (
          <div key={"message-" + message.sid}>
            {renderTimeline(message)}
            <ChatMessage
              variant={isOutbound ? "outbound" : "inbound"}
              key={index}
            >
              <ChatMessageMeta aria-label={`said by ${message.author ?? ""}`}>
                {metaItems}
              </ChatMessageMeta>
              <ChatBubble>
                {wrappedBody}
                {!isOutbound && (
                  <div className="translate-action-text">
                    {isShowButtonHideOriginal(message) && (
                      <a onClick={() => hideOriginalMessage(message)}>
                        {t("hide_original")}
                      </a>
                    )}
                    {isShowButtonShowOriginal(message) && (
                      <a onClick={() => showOriginalMessage(message)}>
                        {t("show_original")}
                      </a>
                    )}
                    {isShowButtonTranslate(message) && (
                      <a onClick={() => doTranslateMessage(message)}>
                        {/* show if the user language has change in show translate message mode
                          show if in the original message
                      */}
                        <>
                          {isShowButtonHideOriginal(message) ||
                          isShowButtonShowOriginal(message)
                            ? " | " + t("translate")
                            : t("translate")}
                          <Spin
                            size="small"
                            spinning={translating === message.sid}
                            style={{ marginLeft: "5px" }}
                          />
                        </>
                      </a>
                    )}
                  </div>
                )}
                <MessageMedia
                  key={message.sid}
                  attachments={conversationAttachments?.[message.sid]}
                  onDownload={async () => await onDownloadAttachments(message)}
                  images={messageImages}
                  files={messageFiles}
                  sending={message.index === -1}
                  onOpen={(
                    mediaSid: string,
                    image?: ReduxMedia,
                    file?: ReduxMedia
                  ) => {
                    if (file) {
                      onFileOpen(
                        conversationAttachments?.[message.sid][mediaSid],
                        file
                      );
                      return;
                    }
                    if (image) {
                      setImagePreview({
                        message,
                        file: conversationAttachments?.[message.sid][mediaSid],
                        sid: mediaSid,
                      });
                    }
                  }}
                />
              </ChatBubble>
            </ChatMessage>
          </div>
        );
      })}
      {imagePreview
        ? (function () {
            const dateString = imagePreview?.message.dateCreated;
            const date = dateString ? new Date(dateString) : "";
            return (
              <ImagePreviewModal
                image={imagePreview.file}
                isOpen={!!imagePreview}
                author={imagePreview?.message.author ?? ""}
                date={
                  date
                    ? date.toDateString() +
                      ", " +
                      date.getHours() +
                      ":" +
                      (date.getMinutes() < 10 ? "0" : "") +
                      date.getMinutes()
                    : ""
                }
                handleClose={() => setImagePreview(null)}
                onDownload={() => {
                  saveAs(
                    imagePreview.file,
                    imagePreview.message.attachedMedia?.find(
                      ({ sid }) => sid === imagePreview.sid
                    )?.filename ?? ""
                  );
                }}
              />
            );
          })()
        : null}
      <div className="scroll-to-view" ref={myRef}></div>
    </ChatLog>
  );
};

export default MessageList;
