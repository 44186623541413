import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { Box, Spinner } from "@twilio-paste/core";

import i18n from "i18next";

import { AppState, actionCreators } from "../../admin/store";
import SingleChatBox from "../SingleChatBox";

import { getClientToken } from "../../services/conversation.service";

import { convertLanguageToCountry } from "../../utils/languageCountryConverter";

import "./style.css";

const ClientAppContainer = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { setUserLanguage, login } = bindActionCreators(
    actionCreators,
    dispatch
  );
  const token = useSelector((state: AppState) => state.token);

  useEffect(() => {
    getClientToken()
      .then((token) => {
        login(token);
      })
      .finally(() => {
        setLoading(false);
      });
    setUserLanguage(convertLanguageToCountry(i18n.language));
  }, []);

  if (loading || !token) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        position="absolute"
        height="100%"
        width="100%"
      >
        <Spinner size="sizeIcon110" decorative={false} title="Loading" />
      </Box>
    );
  }
  return <SingleChatBox />;
};

export default ClientAppContainer;
