import axios from "axios";
import { getIdentity, setIdentity } from "../utils/storage.utils";
import { parseJwt } from "../utils/functions.utils";

const REACT_APP_API_SERVICE_HOST = process.env.REACT_APP_API_SERVICE_HOST;
export const getClientToken = async (): Promise<string> => {
  const userChatCode = getIdentity();
  const url = `${REACT_APP_API_SERVICE_HOST}/ext/v1/token-service`;
  try {
    const response = await axios.get(url, {
      params: { identity: userChatCode },
    });
    console.log("response: ", response);
    const tokenData = parseJwt(response.data);
    setIdentity(tokenData?.grants?.identity);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 401) {
      throw new Error(error.response.data ?? "Authentication error.");
    }

    console.error(`ERROR received from ${url}: ${error}\n`);
    throw new Error(`ERROR received from ${url}: ${error}\n`);
  }
};

export const getClientConversation = async (buildingCode: string) => {
  const userChatCode = getIdentity();
  const url = `${REACT_APP_API_SERVICE_HOST}/ext/v1/conversations`;
  try {
    const response = await axios.get(url, {
      params: { productCode: buildingCode, code: userChatCode },
    });
    console.log("response: ", response);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 401) {
      throw new Error(error.response.data ?? "Authentication error.");
    }

    console.error(`ERROR received from ${url}: ${error}\n`);
    throw new Error(`ERROR received from ${url}: ${error}\n`);
  }
};
