const languageToCountries: { [languageCode: string]: string } = {
  en: "US", // USA
  vi: "VN", // Vietnam
  ko: "KR", // Korea
  ja: "JP", // Japan
  zh: "CN", // China
};

export const convertLanguageToCountry = (languageCode: string): string => {
  return languageToCountries[languageCode] ?? "";
};

export const convertCountryToLanguage = (countryCode: string): string => {
  return (
    Object.keys(languageToCountries).find(
      (key) => languageToCountries[key] === countryCode
    ) ?? ""
  );
};

export const getAllCountries = () => {
  return Object.values(languageToCountries);
};
